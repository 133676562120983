<template>
  <div class="contentView">
    <div class="centerView" v-if="type == 2 && detailObj.remark">
      <div>说明：{{ detailObj.remark }}</div>
      <a-button
        type="primary"
        @click="download"
        style="margin-top: 20px; border-radius: 10px; background: #ff6700; border: 1px solid #ff6700"
        :style="{ width: locale == 'zh_CN' ? '80px' : '100px' }"
        size="large"
      >
        {{ t('download') }}
      </a-button>
    </div>
    <div class="centerView" v-if="type == 2">
      <a-table :dataSource="list" :columns="columns" :pagination="false" :row-key="r => r.id">
        <template #links="{ text }">
          <a-button v-for="(item, index) in text.liveUrlList" type="link" :key="index" size="small" style="margin-bottom: 0" @click="toLink(item)">链接{{ index + 1 }}</a-button>
        </template>
        <template #infos="{ text }">
          {{ text.infos }}
        </template>
      </a-table>
      <div class="pagination_cont">
        <a-pagination v-model:current="page.page" :total="page.total" />
      </div>
    </div>
    <div class="centerView" v-if="type == 1">
      <a-input style="width: 200px; margin-left: 20px" allowClear :placeholder="t('asin')" v-model:value="query.sin" size="large"></a-input>
      <a-select v-model:value="query.brandId" style="width: 200px" :placeholder="t('selectBrand')" allowClear size="large">
        <a-select-option :value="item.id" v-for="(item, index) in brandList" :key="index">{{ item.name }}</a-select-option>
      </a-select>
      <a-button
        type="primary"
        @click="download"
        style="margin-left: 20px; border-radius: 10px; background: #ff6700; border: 1px solid #ff6700"
        :style="{ width: locale == 'zh_CN' ? '80px' : '100px' }"
        size="large"
      >
        {{ t('download') }}
      </a-button>
    </div>
    <div class="centerView" v-if="type == 1" style="padding-top: 0">
      <a-table v-if="!loadRef" :dataSource="list" :columns="columns2" :pagination="false" :row-key="r => r.id" :scroll="{ x: 1600 }" size="small">
        <template #productName="{ text }">
          <a-tooltip placement="top">
            <template #title>
              <span>{{ text }}</span>
            </template>
            <div class="over_hidden">{{ text }}</div>
          </a-tooltip>
        </template>
      </a-table>
      <div style="width: 100%" v-else>
        <a-space :loading="false" :active="true">
          <template v-for="item in 8" :key="item">
            <a-skeleton-button :active="true" style="width: 100%; margin-bottom: 8px; height: 70px"></a-skeleton-button>
          </template>
        </a-space>
      </div>
      <div class="pagination_cont">
        <a-pagination v-model:current="page.page" :total="page.total" @change="pageChange" />
      </div>
    </div>
    <!-- 店播场佣 -->
    <div class="centerView" v-if="type == 8">
      <div style="font-weight: 900; font-size: 24px">
        <span>场次：{{ shopLiveNumber }}</span>
        <span style="margin-left: 20px">应付金额：${{ shopLiveFees }}</span>
      </div>
      <a-button
        type="primary"
        @click="download"
        style="margin-top: 20px; border-radius: 10px; background: #ff6700; border: 1px solid #ff6700"
        :style="{ width: locale == 'zh_CN' ? '80px' : '100px' }"
        size="large"
      >
        {{ t('download') }}
      </a-button>
    </div>
    <!-- 店播场佣 -->
    <div class="centerView" v-if="type == 8">
      <a-table :dataSource="list" :columns="columns3" :row-key="r => r.id">
        <!-- 直播时间 -->
        <template #beginTime="{ record, text }">
          <UTCTransfersTimeZone :time="text" />
        </template>
        <!-- 品牌 -->
        <template #brandName="{ record, text }">
          <div>{{ text }}</div>
        </template>
        <!-- 直播截图 -->
        <template #screenshot="{ record }">
          <a @click="handleShowScreenshotModalVisible(record)">查看</a>
        </template>
        <!-- 直播链接 -->
        <template #liveLink="{ record }">
          <a @click="handleShowLinkModalVisible(record)">查看</a>
        </template>
        <!-- 结算金额 -->
        <template #liveFees="{ record, text }">${{ text }}</template>
      </a-table>
    </div>
    <!-- 店播佣金 -->
    <div class="centerView" v-if="type == 9">
      <div style="font-weight: 900; font-size: 24px">
        <span>销售总金额：${{ salesAmount }}</span>
        <span style="margin-left: 20px">应付金额：${{ shopLiveCommission }}</span>
      </div>
      <a-button
        type="primary"
        @click="download"
        style="margin-top: 20px; border-radius: 10px; background: #ff6700; border: 1px solid #ff6700"
        :style="{ width: locale == 'zh_CN' ? '80px' : '100px' }"
        size="large"
      >
        {{ t('download') }}
      </a-button>
    </div>
    <!-- 店播佣金 -->
    <div class="centerView" v-if="type == 9">
      <a-table :dataSource="list" :columns="columns4" :row-key="r => r.id">
        <!-- 直播时间 -->
        <template #beginTime="{ record, text }">
          <UTCTransfersTimeZone :time="text" />
        </template>
        <!-- 品牌 -->
        <template #brandName="{ record, text }">
          <div>{{ text }}</div>
        </template>
        <!-- 直播截图 -->
        <template #screenshot="{ record }">
          <a @click="handleShowScreenshotModalVisible(record)">查看</a>
        </template>
        <!-- 直播链接 -->
        <template #liveLink="{ record }">
          <a @click="handleShowLinkModalVisible(record)">查看</a>
        </template>
        <!-- 销售额 -->
        <template #salesAmount="{ record, text }">${{ text }}</template>
        <!-- 佣金比例  -->
        <template #commissionRatio="{ record, text }">{{ text }}%</template>
        <!-- 结算金额 -->
        <template #commissionAmount="{ record, text }">${{ text }}</template>
      </a-table>
    </div>
    <!-- 直播链接弹窗 -->
    <a-modal v-model:visible="linkModalVisible" title="直播链接" width="70%" @ok="linkModalVisible = false">
      <div>
        <span>链接1:</span>
        <div v-if="currentLinkArr?.[0]" style="overflow: hidden">
          <a-button :href="currentLinkArr[0]" target="_blank" type="link">{{ currentLinkArr[0] }}</a-button>
        </div>
        <div v-else class="subColor">无</div>
      </div>
      <div>
        <span>链接2:</span>
        <span v-if="currentLinkArr?.[1]">
          <a-button :href="currentLinkArr?.[1]" target="_blank" type="link">{{ currentLinkArr[1] }}</a-button>
        </span>
        <span v-else class="subColor">无</span>
      </div>
    </a-modal>
    <!-- 直播截图弹窗 -->
    <a-modal v-model:visible="screenshotModalVisible" title="直播截图" width="50%" @ok="screenshotModalVisible = false">
      <div class="screenshot-modal-wrap">
        <div v-for="(item, key) in currentScreenshotArr" :key="key">
          <a-popover title="">
            <template #content>
              <img class="screenshot-img-big" :src="formatImg(item, 400, 800, 400, 800)" />
            </template>
            <img class="screenshot-img" :src="formatImg(item, 200, 400, 200, 400)" />
          </a-popover>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
  import { ref, watch } from 'vue'
  // import { getStoreList } from "../../../api/live";
  import { statement_detail, statement_info, statement_excel, statement_brands, getStatementLiveListApi, exportExcelApi } from '../../../api/financial'
  import { useRoute } from 'vue-router'
  import moment from 'moment'
  import { useI18n } from 'vue-i18n'
  import { formatImg } from '@/utils/util.js'
  export default {
    name: 'detail',
    setup() {
      const loadRef = ref(true)
      const columns2 = ref()
      const columns = ref()
      // 店播场佣column
      const columns3 = ref()
      // 店播佣金column
      const columns4 = ref()
      // 场佣场次
      const shopLiveNumber = ref(0)
      // 场佣应付金额
      const shopLiveFees = ref(0)
      // 店播佣金销售金额
      const salesAmount = ref(0)
      // 店播佣金结算金额
      const shopLiveCommission = ref(0)
      const route = useRoute()
      const { t, locale } = useI18n()
      const { type, id } = route.query
      console.log(type)
      console.log(id)
      const list = ref([])

      const query = ref({
        sin: null,
        brandId: null
      })

      const brandList = ref([])

      statement_brands({ id }).then(res => {
        console.log(res)
        brandList.value = res.data
      })

      const page = ref({
        page: 1,
        size: 10,
        total: 0
      })
      // 是否展示直播链接
      const linkModalVisible = ref(false)
      // 是否展示直播截图
      const screenshotModalVisible = ref(false)
      // 当前项的直播链接数组
      const currentLinkArr = ref([])
      // 当前项的直播截图数组
      const currentScreenshotArr = ref([])
      const detailObj = ref({})
      statement_info({ id }).then(res => {
        console.log(res)
        detailObj.value = res.data
      })

      const statement_detailFn = () => {
        loadRef.value = true
        statement_detail({ id, ...query.value, ...page.value }).then(res => {
          setTimeout(() => {
            loadRef.value = false
          }, 1000)
          list.value = res.data.data
          page.value.total = res.data.total
          console.log(list.value)
        })
      }
      /**
       * 获取店播场佣/店播佣金接口
       */
      const getCommissionFn = () => {
        loadRef.value = true
        getStatementLiveListApi({ businessStatementId: id }).then(res => {
          setTimeout(() => {
            loadRef.value = false
          }, 1000)
          list.value = res.data.list
          shopLiveNumber.value = res.data?.shopLiveNumber
          shopLiveFees.value = res.data?.shopLiveFees
          salesAmount.value = res.data?.salesAmount
          shopLiveCommission.value = res.data?.shopLiveCommission
        })
      }
      // 店播场佣与店播佣金时请求新接口
      if (~~type === 8 || ~~type === 9) {
        getCommissionFn()
      } else {
        statement_detailFn()
      }
      watch(
        () => query.value.sin,
        () => {
          page.value.page = 1
          statement_detailFn()
        }
      )

      watch(
        () => query.value.brandId,
        () => {
          page.value.page = 1
          statement_detailFn()
        }
      )

      const pageChange = val => {
        page.value.page = val
        statement_detailFn()
      }
      watch(locale, () => {
        columnFun1()
        columnFun2()
        columnFun3()
        columnFun4()
      })
      const columnFun1 = () => {
        columns.value = [
          {
            title: t('detTab1.merchant'),
            dataIndex: 'businessName',
            key: 'businessName',
            width: 100
          },
          {
            title: t('detTab1.shop'),
            dataIndex: 'storesName',
            key: 'storesName',
            width: 100
          },
          {
            title: t('detTab1.localData'),
            dataIndex: 'localDate',
            key: 'localDate',
            width: 100
          },
          {
            title: t('detTab1.localTime'),
            dataIndex: 'localTime',
            key: 'localTime',
            width: 100
          },
          {
            title: t('detTab1.livelink'),
            width: 100,
            slots: { customRender: 'links' }
          },
          {
            title: 'Views',
            dataIndex: 'views',
            key: 'views',
            width: 60
          },
          {
            title: t('detTab1.pit'),
            dataIndex: 'activeGoodsNumber',
            key: 'activeGoodsNumber',
            width: 50
          },
          {
            title: t('detTab1.pitPrice'),
            dataIndex: 'feesUSD',
            key: 'feesUSD',
            width: 60
          },
          {
            title: t('detTab1.brand'),
            width: 150,
            slots: { customRender: 'infos' }
          },
          {
            title: t('detTab1.clickrate'),
            dataIndex: 'clickThroughRate',
            key: 'clickThroughRate',
            width: 60
          }
        ]
      }
      const columnFun2 = () => {
        columns2.value = [
          {
            title: t('detTab2.month'),
            dataIndex: 'month',
            key: 'month',
            width: 80
          },
          {
            title: t('detTab2.dataTime'),
            dataIndex: 'dateTime',
            key: 'dateTime',
            width: 90
          },
          {
            title: t('detTab2.shop'),
            dataIndex: 'storeName',
            key: 'storeName',
            width: 70
          },
          {
            title: 'Category',
            dataIndex: 'category',
            key: 'category',
            width: 150
          },
          {
            title: 'Name',
            dataIndex: 'productName',
            key: 'productName',
            width: 200,
            slots: { customRender: 'productName' }
          },
          {
            title: 'ASIN',
            dataIndex: 'sin',
            key: 'sin',
            width: 100
          },
          {
            title: t('detTab2.brand'),
            dataIndex: 'brandName',
            key: 'brandName',
            width: 70
          },
          {
            title: 'Date Shipped',
            dataIndex: 'dateShipped',
            key: 'dateShipped',
            width: 100
          },
          {
            title: 'Price($)',
            dataIndex: 'priceUSD',
            key: 'priceUSD',
            width: 90
          },
          {
            title: 'Shipped',
            dataIndex: 'itemsShipped',
            key: 'itemsShipped',
            width: 90
          },
          {
            title: 'Returns',
            dataIndex: 'returns',
            key: 'returns',
            width: 80
          },
          {
            title: 'Revenue($)',
            dataIndex: 'revenueUSD',
            key: 'revenueUSD',
            width: 120
          },
          {
            title: t('detTab2.commissionType'),
            dataIndex: 'commissionRatio',
            key: 'commissionRatio',
            width: 120
          },
          {
            title: t('detTab2.commission'),
            dataIndex: 'commissionFees',
            key: 'commissionFees',
            width: 120
          }
        ]
      }
      /**
       * 店播场佣
       */
      const columnFun3 = () => {
        columns3.value = [
          {
            title: '直播时间',
            dataIndex: 'beginTime',
            key: 'beginTime',
            width: 150,
            align: 'center',
            slots: {
              customRender: 'beginTime'
            }
          },
          {
            title: '品牌',
            dataIndex: 'brandName',
            key: 'brandName',
            align: 'center',
            width: 120,
            slots: {
              customRender: 'brandName'
            }
          },
          {
            title: '直播链接',
            dataIndex: 'liveLink',
            key: 'liveLink',
            align: 'center',
            width: 120,
            slots: {
              customRender: 'liveLink'
            }
          },
          {
            title: '直播截图',
            dataIndex: 'screenshot',
            key: 'screenshot',
            align: 'center',
            width: 150,
            slots: {
              customRender: 'screenshot'
            }
          },
          {
            title: '结算金额',
            dataIndex: 'liveFees',
            key: 'liveFees',
            align: 'center',
            width: 150,
            slots: {
              customRender: 'liveFees'
            }
          }
        ]
      }
      /**
       * 店播佣金
       */
      const columnFun4 = () => {
        columns4.value = [
          {
            title: '直播时间',
            dataIndex: 'beginTime',
            key: 'beginTime',
            width: 150,
            align: 'center',
            slots: {
              customRender: 'beginTime'
            }
          },
          {
            title: '品牌',
            dataIndex: 'brandName',
            key: 'brandName',
            align: 'center',
            width: 120,
            slots: {
              customRender: 'brandName'
            }
          },
          {
            title: '直播链接',
            dataIndex: 'liveLink',
            key: 'liveLink',
            align: 'center',
            width: 120,
            slots: {
              customRender: 'liveLink'
            }
          },
          {
            title: '直播截图',
            dataIndex: 'screenshot',
            key: 'screenshot',
            align: 'center',
            width: 150,
            slots: {
              customRender: 'screenshot'
            }
          },
          {
            title: '销售额',
            dataIndex: 'salesAmount',
            key: 'salesAmount',
            align: 'center',
            width: 150,
            slots: {
              customRender: 'salesAmount'
            }
          },
          // {
          //   title: '佣金比例',
          //   dataIndex: 'commissionRatio',
          //   key: 'commissionRatio',
          //   align: 'center',
          //   width: 150,
          //   slots: {
          //     customRender: 'commissionRatio'
          //   }
          // },
          {
            title: '结算金额',
            dataIndex: 'commissionAmount',
            key: 'commissionAmount',
            align: 'center',
            width: 150,
            slots: {
              customRender: 'commissionAmount'
            }
          }
        ]
      }
      columnFun1()
      columnFun2()
      columnFun3()
      columnFun4()
      const toLink = url => {
        window.open(url)
      }
      /**
       * 显示链接弹窗
       */
      const handleShowLinkModalVisible = record => {
        currentLinkArr.value = record?.liveUrlList
        linkModalVisible.value = true
      }
      /**
       * 显示直播截图弹窗
       */
      const handleShowScreenshotModalVisible = record => {
        currentScreenshotArr.value = record?.liveScreenshotList
        screenshotModalVisible.value = true
      }
      const download = () => {
        let companyName = localStorage.getItem('userName')
        let fileName
        if (type == 1) {
          fileName = companyName.split('@')[1] + '_commission_' + moment().format('YYYY-MM-DD') + '.xlsx'
        } else if (type == 8 || type == 9) {
          fileName = companyName.split('@')[1] || companyName + '_live_fee_' + moment().format('YYYY-MM-DD') + '.xlsx'
        } else {
          fileName = companyName.split('@')[1] + '_spot_fee_' + moment().format('YYYY-MM-DD') + '.xlsx'
        }
        if (type == 8 || type == 9) {
          exportExcelApi(
            {
              businessStatementId: id
            },
            {
              fileName: fileName
            }
          )
        } else {
          statement_excel(
            {
              id
            },
            {
              fileName: fileName
            }
          )
        }
      }

      return {
        locale,
        loadRef,
        brandList,
        download,
        toLink,
        detailObj,
        query,
        columns2,
        columns3,
        columns4,
        shopLiveNumber,
        shopLiveFees,
        salesAmount,
        shopLiveCommission,
        type,
        columns,
        pageChange,
        list,
        page,
        t,
        linkModalVisible,
        handleShowLinkModalVisible,
        handleShowScreenshotModalVisible,
        screenshotModalVisible,
        currentLinkArr,
        currentScreenshotArr,
        formatImg
      }
    }
  }
</script>

<style scoped lang="less">
  ::v-deep(.ant-space-align-center) {
    width: 100%;
  }
  ::v-deep(.ant-space-item) {
    width: 100%;
  }
  ::v-deep(.ant-space) {
    display: flex;
    flex-direction: column;
  }
  ::v-deep(.ant-input-affix-wrapper) {
    border-radius: 10px;
  }
  .over_hidden {
    word-break: break-all;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2; /* 这里是超出几行省略 */
    overflow: hidden;
  }
  .contentView {
    // min-width: 1200px;
  }
  .screenshot-img {
    width: 100px;
    object-fit: contain;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .screenshot-img-big {
    width: 200px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .screenshot-modal-wrap {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    overflow: hidden;
  }
</style>
